var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-comment-container"
  }, [_c('div', {
    staticClass: "board-comment-head"
  }, [_c('span', {
    staticClass: "board-comment-head__total"
  }, [_vm._v("댓글 "), _c('em', [_vm._v(_vm._s(_vm.board._comments.length))])]), _c('form', {
    staticClass: "board-comment-head__form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.comment.content,
      expression: "comment.content"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "댓글을 남겨주세요."
    },
    domProps: {
      "value": _vm.comment.content
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.comment, "content", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "button button--primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_vm._v("등록")])])]), _c('div', {
    staticClass: "board-comment-body"
  }, [_c('ul', {
    staticClass: "board-comment-lists"
  }, _vm._l(_vm.board.comments, function (comment) {
    return _c('li', {
      key: comment._id,
      staticClass: "board-comment-list"
    }, [_c('div', {
      staticClass: "board-comment-block"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('span', {
      staticClass: "id"
    }, [_vm._v(_vm._s(comment.writer.nickname))]), _c('p', {
      staticClass: "content"
    }, [_vm._v(_vm._s(comment.content))])]), _c('div', {
      staticClass: "row"
    }, [_c('ul', {
      staticClass: "infos"
    }, [_c('li', [_vm._v(_vm._s(comment.createdAt.toDate()))]), _c('li', [_c('button', {
      staticClass: "like",
      class: {
        'like--on': _vm.isLike(comment)
      },
      on: {
        "click": function ($event) {
          !_vm.isLike(comment) ? _vm.$emit('like', comment) : _vm.$emit('unlike', comment);
        }
      }
    }, [_vm._v("좋아요")]), _c('span', [_vm._v(_vm._s(comment.likeCount))])])])])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }