<template>
    <div class="board-comment-container">
        <div class="board-comment-head">
            <span class="board-comment-head__total">댓글 <em>{{ board._comments.length }}</em></span>
            <form class="board-comment-head__form">
                <input class="input" type="text" v-model="comment.content" placeholder="댓글을 남겨주세요.">
                <button class="button button--primary" type="button" @click="$emit('save')">등록</button>
            </form>
        </div>
        <div class="board-comment-body">
            <ul class="board-comment-lists">
                <li class="board-comment-list" v-for="comment in board.comments" :key="comment._id">
                    <div class="board-comment-block">
                        <div class="row">
                            <span class="id">{{ comment.writer.nickname }}</span>
                            <p class="content">{{ comment.content }}</p>
                        </div>
                        <div class="row">
                            <ul class="infos">
                                <li>{{ comment.createdAt.toDate() }}</li>
                                <li>
                                    <button class="like" :class="{ 'like--on' : isLike(comment) }" @click="!isLike(comment) ? $emit('like', comment) : $emit('unlike', comment)">좋아요</button>
                                    <span>{{ comment.likeCount }}</span>
                                </li>
                                <!-- <li>답글 달기</li> -->
                                <!-- <li><button class="repo" type="button">신고</button></li> -->
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null },
        board: { type: Object, default: null },
        me: { type: Object, default: null }
    },
    data(){
        return {
            comment: this.$props.value,
        };
    },
    methods: {
        isLike(comment){
            return this.me ? this.me.likes.find(like => like._comment == comment._id) : false;
        }
    },
    watch: {
        value(){
            this.comment = this.value;
        }
    }
}
</script>